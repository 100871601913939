import { Component ,OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CanonicalService } from '../../../shared/canonical.service';

@Component({
  selector: 'app-corporate-blog',
  templateUrl: './corporate-blog.component.html',
  styleUrls: ['./corporate-blog.component.css']
})
export class CorporateBlogComponent implements OnInit {
  items:any = []
    constructor(
      private modalService: NgbModal,
      private metaTagService: Meta,
      private titleService: Title,
      public http: HttpClient,
      private canonicalService: CanonicalService,
    ) { }
    
    ngOnInit(){
      this.canonicalService.setCanonicalURL();
      this.metaTagService.addTags([
        { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเกี่ยวกับเรา,เกี่ยวกับ ซิสเนค,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Sysnect Information Co., Ltd.' },
        {name: 'description', content: 'Sysnect Information เป็น บริษัทฯ ที่รับพัฒนาระบบให้กับลูกค้าทั้งรายเล็กและรายใหญ่ รวมไปถึงการบริการด้านต่างๆ อาทิ การดูแลเว็บไซต์ เป็นต้น'},
        { charset: 'UTF-8' }
      ]);
      this.titleService.setTitle("ข่าวสาร - Sysnect Information Co., Ltd.");
  
      this.http.get('https://api.sysnect.co.th/frontend/v1/posts')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.items = res.data
        }
    })
    }
}
