import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CanonicalService } from '../../../shared/canonical.service';

declare let gtag: Function;
@Component({
  selector: 'app-page-aboutus-two',
  templateUrl: './page-aboutus-two.component.html',
  styleUrls: ['./page-aboutus-two.component.css']
})

/**
 * Aboutus Two Component
 */
export class PageAboutusTwoComponent implements OnInit {
  navClass = 'nav-light';
  year = new Date().getFullYear()*1 - 2001


  partnerData = [
  ];
  customerData= [
  ];
  constructor(
    private modalService: NgbModal,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    public http: HttpClient,
    private titleService: Title,) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเกี่ยวกับเรา,เกี่ยวกับ ซิสเนค,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Sysnect Information Co., Ltd.' },
      {name: 'description', content: 'Sysnect Information เป็น บริษัทฯ ที่รับพัฒนาระบบให้กับลูกค้าทั้งรายเล็กและรายใหญ่ รวมไปถึงการบริการด้านต่างๆ อาทิ การดูแลเว็บไซต์ เป็นต้น'},
      { charset: 'UTF-8' }
    ]);
    this.titleService.setTitle("เกี่ยวกับเรา - Sysnect Information Co., Ltd.");

    this.http.get('https://api.sysnect.co.th/frontend/v1/customers')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.customerData = res.data
        }
    })
    this.http.get('https://api.sysnect.co.th/frontend/v1/partners')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.partnerData = res.data
        }
    })
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
}
