import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';

import { ScrollspyDirective } from './scrollspy.directive';
import { FeatherModule } from 'angular-feather';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { PartnerComponent } from './partner/partner.component';

import { ClientsLogoComponent } from './clients-logo/clients-logo.component';
import { ServicesComponent } from './services/services.component';
import { PostsComponent } from './posts/posts.component';

@NgModule({
  declarations: [
    ScrollspyDirective,
    ClientsLogoComponent,
    TestimonialComponent,
    PartnerComponent,
    ServicesComponent,
    PostsComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    FeatherModule,
    RouterModule
  ],
  exports: [
    ScrollspyDirective,
    ClientsLogoComponent, 
    ServicesComponent, 
    TestimonialComponent,
    PostsComponent,
  ]
})

export class SharedModule { }
