
<section class="section mt-3" *ngIf="type=='cloud'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">ผลิตภัณฑ์เทคโนโลยีสารสนเทศ</h4>
          <p class="text-muted">
            ทุกวันนี้ระบบเทคโนโลยีสารสนเทศนับเป็นสิ่งจำเป็นสำหรับองค์กร ทุกองค์กร ทางบริษัท ซิสเนค อินฟอเมชัน จำกัด เล็งเห็นถึงความสำคัญในการให้บริการของในส่วนสินค้า IT, การให้คำปรึกษา, การวางระบบเครือข่าย, การออกแบบระบบงาน และออกแบบ Software โดยเน้นถึงประโยชน์สูงสุดของลูกค้าเป็นสำคัญ
	ปัจจุบันสินค้าและบริการในกลุ่มผลิตภัณฑ์เทคโนโลยีสารสนเทศ แบ่งได้ดังต่อไปนี้
          </p>
          <ul class="list-unstyled text-muted">
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Computer Server, PC และ Notebook ทางบริษัทมีสินค้าประเภทเครื่องคอมพิวเตอร์แม่ข่ายสำหรับองค์กร, คอมพิวเตอร์แบบตั้งโต๊ะ, โน๊ตบุ๊คและ แท็ปเบล็ตพีซี ให้เลือกหลายรุ่น หลายแบบโดยเน้นถึงความเหมาะสมกับลูกค้าเป็นสำคัญ ทั้งนี้ทางบริษัทเป็นตัวแทนจำหน่ายโดยตรงจาก บริษัท เอเซอร์ คอมพิวเตอร์ จำกัด, บริษัท เลอโนโว (ประเทศไทย) จำกัด, บริษัท ฮิวเล็ตต์-แพคการ์ด (ประเทศไทย) จำกัดและ บริษัท ไอ บี เอ็ม จำกัด เป็นต้น </li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Super Computer ทางบริษัทเป็นตัวแทนจำหน่ายเครื่อง Super Computer CRAY โดยนำผู้เชี่ยวชาญโดยตรงจากต่างประเทศมาเป็นผู้ดำเนินการพัฒนาระบบเพื่อสนับสนุนงานที่ต้องอาศัยการประมวลผลขั้นสูง อาทิ การวิจัย การจำลองการทดลองด้านวิทยาศาสตร์</li>              
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>UNIX System สำหรับองค์กรที่ใช้ระบบปฏิบัติการ UNIX ทางบริษัทสามารถให้บริการท่านในการออกแบบ และพัฒนาพร้อมทั้งจัดจำหน่ายระบบ Sun Microsystems โดยผู้เชี่ยวชาญ</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Network Equipment ทางบริษัทเป็นตัวแทนจำหน่ายอุปกรณ์เครือข่ายของ Cisco, 3Com, Zyxel, D-Link และ Linksys นอกจากนี้ทางบริษัทยังรับปรึกษาและออกแบบระบบเครือข่ายต่างๆ</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>อุปกรณ์ต่อเชื่อมอื่นๆ นอกเหนือจากนี้ทางบริษัทยังเป็นตัวแทนจำหน่ายอุปกรณ์ต่อพ่วงอื่นๆ อีกมากมายอาทิ Printer และ Scanner ของ HP และ ระบบสำรองไฟฟ้าของ Powermatic, APC และ Liebert Emerson เป็นต้น</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Software Design and Implementation ทางบริษัทยินดีรับเป็นที่ปรึกษาวิเคราะห์ระบบและออกแบบซอร์ฟแวร์ และให้คำปรึกษาแก่องค์กร ไม่ว่าจะเป็นระบบโรงงาน (Enterprise Resource Planning) ระบบการศึกษา หรือ องค์กรต่างๆ</li>
              <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>


          </ul>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="assets/images/service/compute-server.jpeg"
          class="col-12"
          alt="">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
</section>
<section class="section mt-3" *ngIf="type=='barcode'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">อุปกรณ์บาร์โค้ด</h4>
          <p class="text-muted">
            ปัจจุบันการจัดการบริหารงานด้านระบบสินค้าคงคลังนับเป็นสิ่งจำเป็นในองค์กรหลายแห่ง สิ่งหนึ่งซึ่งช่วยอำนวยความสะดวกได้อย่างมากคือการใช้อุปกรณ์บาร์โค้ดเชื่อมต่อกับฐานข้อมูลคอมพิวเตอร์ ทางบริษัทนอกจากจะเป็นตัวแทนจำหน่ายสินค้าหลายตัว อาทิ
          </p>
          <ul class="list-unstyled text-muted">
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>เครื่องอ่านบาร์โค้ด Datalogic, PCS และ Twincom</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>เครื่องพิมพ์บาร์โค้ด  Zebra</li>              
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>อุปกรณ์บาร์โค้ดสำหรับอุตสาหกรรม UNITEC</li>
              <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาและออกแบบระบบดังกล่าวเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>
             

          </ul>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="assets/images/service/barcode.jpeg"
          class="col-12"
          alt="">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
</section>
<section class="section mt-3" *ngIf="type=='meeting'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">ระบบห้องประชุมและการเรียนการสอนทางอิเล็กทรอนิกส์</h4>
          <p class="text-muted">
            ทางบริษัทรับออกแบบ และติดตั้งห้องประชุม ระบบเสียง ระบบภาพ ทั้งนี้รวมถึง ห้องส่ง ห้องตัดต่อต่างๆ โดยใช้อุปกรณ์ที่มีชื่อเสียงในด้านนี้โดยเฉพาะ ทั้งนี้รวมถึงการออกแบบระบบการประชุมทางไกล (Video Conference) และระบบการเรียนการสอนทางอิเล็กทรอนิกส์ ซึ่งทางบริษัทเป็นตัวแทนของอุปกรณ์และโปรแกรมต่างๆ ดังนี้
          </p>
          <ul class="list-unstyled text-muted">
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>ระบบการประชุมทางไกลผ่านระบบอินเตอร์เน็ต (Video Conference) ของ Sony และ Tandberg</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>โปรแกรมสนับสนุนการเรียนการสอนทางไกลผ่านระบบอินเตอร์เน็ต ของ Aculearn</li>              
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>ระบบการตัดต่อภาพและเสียงของ AVID</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>โปรเจคเตอร์และระบบการแสดงภาพ ของ Samsung, Sony, Optoma, Epson และ Toshiba</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>ระบบเสียงห้องประชุมของ TOA</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>พัฒนาระบบการประชุมทางไกลผ่านโปรแกรม Openmeeting ของ Google</li>
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>พัฒนาระบบการเรียนการสอนทางอินเตอร์เน็ตผ่านโปรแกรม Moodle</li>
              <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>
             

          </ul>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="assets/images/service/meeting.jpeg"
          class="col-12"
          alt="">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
</section>
<section class="section mt-3" *ngIf="type=='kiosk'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">ระบบเฉพาะทาง</h4>
          <p class="text-muted">
            ทางบริษัทรับออกแบบระบบเทคโนโลยีสารสนเทศอื่นๆ ที่ใช้ทำงานเฉพาะทางเพื่อนำมาทำการควบคุม หรืองานอื่นๆ เช่น ระบบควบคุมการทำงานเครื่องจักร, ระบบ Kiosk และ เครื่องคอมพิวเตอร์ควบคุมขนาดเล็กแบบ Industrial Grade เป็นต้น          </p>
          <ul class="list-unstyled text-muted">
              <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>
             

          </ul>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="assets/images/service/kiosk.jpeg"
          class="col-12"
          alt="">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
</section>
<section class="section mt-3" *ngIf="type=='security'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">ระบบความปลอดภัย</h4>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>RFIDsec ซึ่งเป็นระบบแรกของโลกที่ได้นำเอาการเข้ารหัสมาผสานกับเทคโนโลยี RFID ซึ่งผลิตภัณฑ์ดังกล่าวได้รับรางวัล Danish Innovation Award ซึ่งจะเป็นการขจัดปัญหาการปลอมแปลง และการโจรกรรมผ่านทางระบบ RFID</li>
            <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Digital Nanoparticle Authentication เป็นระบบที่อาศัยนาโนเทคโนโลยีเพื่อใช้ในการป้องกันการปลอมแปลงซึ่งคิดค้นขึ้นโดย CSIRO อันเป็นสถาบันวิจัยขนาดใหญ่ซึ่งได้รับเงินทุนสนับสนุนจากรัฐบาลออสเตรเลีย ซึ่งจะช่วยให้เราตรวจสอบเอกสาร วัตถุ ชิ้นงาน ว่าเป็นของจริงหรือของที่ปลอมแปลง</li>
            <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;margin-left: 30px;"><i class="fa-solid fa-splotch align-middle"></i></span>Network Security Equipment ทางบริษัทยังเป็นตัวแทนจำหน่ายและติดตั้งอุปกรณ์ความปลอดภัยทางระบบเน็ตเวิร์กและระบบอินเตอร์เน็ตสำหรับองค์กร ได้แก่ อุปกรณ์ UTM Firewall ของ Fortinet และ Juniper อุปกรณ์ IPS ของ Tipping Point และอุปกรณ์ e-Mail Security ของProofpoint </li>

            <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>
             

          </ul>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="assets/images/service/security.jpeg"
          class="col-12"
          alt="">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
</section>

<section class="section mt-3" *ngIf="type=='uguard'">
  <!-- Rate Start -->
  <div class="container mt-40 mt-60">
    <img src="https://cdn.sysnect.co.th/images/partner/uguard/UGUARD+LOGO/UGUARD_logo_horizontal_5.png" height="60" style="padding: 10px; border-radius: 0;">
  </div>
  <div class="container mt-40 mt-60">
    <div class="row align-items-center">
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="https://cdn.sysnect.co.th/images/partner/uguard/AgileLInk/AL1000B.png" 
          alt="SD-WAN" 
          class="col-12">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h2>AgileLink</h2>
          <p>AgileLink is an advanced WAN management solution
            designed to enhance network efficiency, security, and
            reliability. With features such as Global Traffic Collaboration,
            WarpTunnel VPN technology, and real-time traffic analysis,
            AgileLink ensures optimal performance for your enterprise's
            WAN infrastructure.</p>
      
          <h2>Key Features</h2>
          <ul class="features-list">
              <li><strong>Global Traffic Collaboration (GTC):</strong> Efficiently manages and
                optimizes internet traﬃc from external networks to internal services across multiple lines, optimizing line bandwidth usage and improving service quality.</li>
              <li><strong>WarpTunnel VPN Technology:</strong> Ensures high-quality point-to-point VPN transmission for critical services across diﬀerent WAN connections.</li>
              <li><strong>Application Traffic Control:</strong> Supports traffic control for over 8,400 applications, including YouTube, Skype, and P2P platforms.</li>
              <li><strong>Real-Time Traffic Analysis:</strong> Provides comprehensive real- time data on traffic, top applications, and users.</li>
              <li><strong>Advanced Security Features:</strong> Includes DoS protection, URL filtering, and Layer 7 QoS to safeguard your network.</li>
              <li><strong>L7 WAN Link Load Balancing:</strong> Ensures continuous and optimal connectivity by rerouting traffic during route failures.</li>
          </ul>
          <a href="https://cdn.sysnect.co.th/images/partner/uguard/Datasheets/20240920/AgileLink_datasheet_en_240920.pdf" target="_blank">Learn more about AgileLink</a>
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h2>Defense Trust</h2>
          <p>Defense Trust is UGUARD's next-generation threat management system, designed to effectively neutralize over 80% of known malicious websites including phishing, malware downloads, and botnet activities.</p>
          <p>It provides high-speed inline analysis, using the latest threat intelligence and our proprietary analysis engine. The system ensures real-time updates and minimal false positives, giving you the best protection with the least disruption.</p>
          <p><strong>Key Features:</strong></p>
          <ul>
              <li>Global IP Identification and Analysis</li>
              <li>Precise Blocking of Known Threats</li>
              <li>Real-time Threat Intelligence Updates</li>
              <li>Integrated 3rd Party Threat Intelligence</li>
          </ul>
          <a href="https://cdn.sysnect.co.th/images/partner/uguard/Datasheets/20240920/Traffic+Shaper+_datasheet_en_240909.pdf" target="_blank">Learn more about Defense Trust</a>
        </div>
      </div>
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="https://cdn.sysnect.co.th/images/partner/uguard/Defense+Trust/DT-1001.png" 
          alt="Defense Trust" 
          class="col-12">
        </div>
      </div>
      <!--end col-->
      </div>
    </div>

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="mt-3 col-lg-6 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <img 
          src="https://cdn.sysnect.co.th/images/partner/uguard/SD-WAN/SD-WAN_SD100.png" 
          alt="SD-WAN" 
          class="col-12">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h2>SD-WAN</h2>
          <p>UGUARD's SD-WAN offers flexible, scalable, and secure wide area network solutions. It allows seamless integration of multiple platforms and transmission services, ensuring business continuity with its redundancy and load balancing capabilities.</p>
          <p><strong>Key Features:</strong></p>
          <ul>
              <li>Centralized SD-WAN Management</li>
              <li>Intrusion Prevention System (IPS)</li>
              <li>Connection Failover and Health Check</li>
              <li>Application Traffic Control</li>
          </ul>
          <a href="https://cdn.sysnect.co.th/images/partner/uguard/Datasheets/20240920/SDWAN+datasheet_en_0918.pdf" target="_blank">Learn more about SD-WAN</a>
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="section-title ms-lg-5">
            <h2>Traffic Shaper</h2>
            <p>UGUARD's Traffic Shaper is an AI-driven traffic management system that provides comprehensive traffic control for enterprise networks. It allows precise bandwidth management, application identification, and real-time traffic analysis.</p>
            <p><strong>Key Features:</strong></p>
            <ul>
                <li>Layer 7 Application Identification</li>
                <li>Advanced Bandwidth Management</li>
                <li>Cross-Tier Traffic Recognition</li>
                <li>Integrated AAA Authentication</li>
            </ul>
            <a href="https://cdn.sysnect.co.th/images/partner/uguard/Datasheets/20240920/Traffic+Shaper+_datasheet_en_240909.pdf" target="_blank">Learn more about Traffic Shaper</a>
          </div>
        </div>
        <div class="mt-3 col-lg-6 col-md-6 col-12">
          <div class="mt-4 pt-2 text-center">
            <img 
            src="https://cdn.sysnect.co.th/images/partner/uguard/Traffic+Shaper/NGT-1000A.png" 
            alt="Traffic Shaper" 
            class="col-12">
          </div>
        </div>
        <!--end col-->
        </div>
      </div>
</section>
<section class="section mt-3" *ngIf="type=='hardware'">
  <!-- Rate Start -->
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 col-12">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">ฮาร์ดแวร์&ซอฟต์แวร์</h4>
          <p class="text-muted">ผลิตภัณฑ์ที่หลายหลายทางเรามีผู้เชี่ยวชาญที่สามารถแนะนำฮาร์ดแวร์ที่ตอบโจทย์ในองค์กรแต่ละองค์กรด้วยประสบการณ์ที่มากกว่า</p>
          <ul class="list-unstyled text-muted">
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;"><i class="uil uil-check-circle align-middle"></i></span>Dell,Lenovo,Acer,Asus,Apple</li>        
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;"><i class="uil uil-check-circle align-middle"></i></span>H3C,Sangfor,UGUARD</li>        
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;"><i class="uil uil-check-circle align-middle"></i></span>Synology,Ablerex</li>        
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;"><i class="uil uil-check-circle align-middle"></i></span>Sangfor, Deep Instinct, Microsoft Windows, MS Office</li>    
              <li class="mb-1"><span class="h5 me-2" style="color: #EF3C59;"><i class="uil uil-check-circle align-middle"></i></span>APC</li>          
              <li class="mt-3">ทางบริษัทยังรับให้คำปรึกษาเพื่อประโยชน์สูงสุดแก่องค์กรของท่าน</li>

          </ul>
          
        </div>
      </div>
      <div class="mt-3 col-lg-5 col-md-6 col-12">
        <div class="mt-4 pt-2 text-center">
          <a href="javascript:void()">
            <img 
            src="assets/images/device/1.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Dell"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/2.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Lenovo"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/3.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Acer"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/4.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="H3C"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/5.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Apple"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/6.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Asus"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/7.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="APC"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/8.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Synology"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/9.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Ablerex"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/10.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Sangfor"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/11.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Deep Instinct"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/12.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="Windows"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="assets/images/device/13.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="MS Office"
            alt="">
          </a>
          <a href="javascript:void()">
            <img 
            src="https://cdn.sysnect.co.th/images/partner/uguard.png"
            class="img-fluid m-2 p-2 rounded-circle shadow" 
            placement="top" 
            width="100"
            ngbTooltip="UGUARD"
            alt="">
          </a>
        </div>
      </div>
      <!--end col-->
      </div>
    </div>
  </section>
  <!--end section-->
  <!-- Section End -->
<!-- Back to top -->
<a href="javascript:void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->