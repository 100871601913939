<owl-carousel-o id="customer-testi" [options]="customOptions">
    <ng-container *ngFor="let data of testimonialData">
        <ng-template carouselSlide>
            <div class="tiny-slide">
                <div class="d-flex client-testi m-2">
                    <img src="{{data.image}}" width="200" height="200" style="object-fit: cover; object-position: center;" alt="">
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>