import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { CanonicalService } from '../../../shared/canonical.service';

declare let gtag: Function;
@Component({
  selector: 'app-helpcenter-faqs',
  templateUrl: './helpcenter-faqs.component.html',
  styleUrls: ['./helpcenter-faqs.component.css']
})

/**
 * Helpcenter Faqs Component
 */
export class HelpcenterFaqsComponent implements OnInit {


  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private canonicalService: CanonicalService,
    ) { }
    
  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareคำถามที่พบบ่อย,คำถามทั่วไป,วิธีการชำระเงิน,นโยบายการคืนเงิน,นโยบายความเป็นส่วนตัว,นโยบายการแก้ไขปัญหาข้อร้องเรียน,การช่วยเหลือ,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Sysnect Information Co., Ltd.' },
      {name: 'description', content: 'คำถามที่พบบ่อย วิธีการชำระเงิน นโยบายการคืนเงิน การช่วยเหลือ นโยบายการแก้ไขปัญหาข้อร้องเรียน นโยบายความเป็นส่วนตัว นโยบายคุกกี้'},
      { charset: 'UTF-8' }
    ]);
    this.titleService.setTitle("คำถามที่พบบ่อย - Sysnect Information Co., Ltd.");
  }

}
