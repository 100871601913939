<div class="row justify-content-center">
    <div  *ngFor="let data of items" class="col-lg-4 col-md-6 col-12 mb-4 pb-2">
        <div class="card border-0 blog blog-primary shadow overflow-hidden">
            <img [src]="data.thumbnail" class="img-fluid" alt="">

            <div class="content card-body">
                <ul class="list-unstyled d-flex justify-content-between">
                    <li class="text-muted">22nd June</li>
                    <li class="text-muted"><a href="javascript:void(0)"
                            class="badge bg-soft-primary">Business</a></li>
                </ul>

                <h5><a href="javascript:void(0)" class="card-title title text-dark">How apps is changing the
                        IT</a></h5>

                <div class="post-meta d-flex justify-content-between mt-3">
                    <a routerLink="/blog-detail" class="text-muted readmore">Read More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                </div>
            </div>
        </div>
    </div><!--end col-->
</div>


