<!-- Navbar STart -->
<header id="topnav" class="defaultscroll stick tagline-heighty new-header" style="background: #FFF; color: black;" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" href="javascript:void(0);"  routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img src="https://cdn.sysnect.co.th/images/logo.png" class="l-dark logo-light-mode" height="50" alt="">
      </a>
      <a class="logo" href="javascript:void(0);"  routerLink="/" *ngIf="navClass == 'nav-light'">
        <img src="https://cdn.sysnect.co.th/images/logo.png" class="l-dark" height="50" alt="">
        <img src="https://cdn.sysnect.co.th/images/logo.png" class="l-light" height="50" alt="">
      </a>
    </div>
    <!--end login button-->

    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" >
        <li><a routerLink="/" class="nav-link-ref">หน้าหลัก</a></li>
        <li><a routerLink="/about-us" class="nav-link-ref">เกี่ยวกับเรา</a></li>
        <li><a routerLink="/news" class="nav-link-ref">ข่าวสาร</a></li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">บริการของเรา</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <ul>
                <li *ngFor="let rs of  servicesData"><a class="nav-link-ref" routerLink="/{{rs.id}}">{{rs.title}}</a></li>
              </ul>
            </li>
        </ul>
        <li><a routerLink="/contact" class="nav-link-ref">ติดต่อเรา</a></li>
      </ul>
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->