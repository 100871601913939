<!-- ERROR PAGE -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <div class="text-uppercase mt-4 display-3">Oh ! no</div>
        <div class="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->