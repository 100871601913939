import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpcenterFaqsComponent } from './core/components/helpcenter-faqs/helpcenter-faqs.component';
import { IndexBusinessComponent } from './core/components/index-business/index-business.component';
import { IndexServicesComponent } from './core/components/index-services/index-services.component';
import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
import { PageContactThreeComponent } from './core/components/page-contact-three/page-contact-three.component';
import { PageErrorComponent } from './core/components/page-error/page-error.component';
import { CorporateBlogComponent } from './core/components/corporate-blog/corporate-blog.component';
import { CorporateBlogDetailComponent } from './core/components/corporate-blog-detail/corporate-blog-detail.component';

declare let gtag: Function;
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: IndexBusinessComponent },
      { path: 'about-us', component: PageAboutusTwoComponent },
      { path: 'service/:id', component: IndexServicesComponent },
      { path: 'faqs', component: HelpcenterFaqsComponent },
      { path: 'contact', component: PageContactThreeComponent },
      { path: 'error', component: PageErrorComponent },
      { path: 'news', component: CorporateBlogComponent },
      { path: 'news/:id', component: CorporateBlogDetailComponent },
      { path: '**', component: PageErrorComponent },
    ],
    
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
