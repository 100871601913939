<div class="row">
    <div class="col-md-4 col-12 mt-5" *ngFor="let data of servicesData">
        <a href="javascript:void(0);"  routerLink="/{{data.id}}" style="color: #000;">
            <div class="features features text-center">
                <div class="">
                <img src="{{data.icon}}" style="border-radius: 0%;" height="100">
                </div>

                <div class="content mt-4">
                    <h5>{{data.title}}</h5>
                    <p class="text-muted mb-0">{{data.description}}</p>
                </div>
            </div>
        </a>
    </div>
    <!--end col-->
</div>