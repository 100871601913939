import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CanonicalService } from '../../../shared/canonical.service';
declare let gtag: Function;
@Component({
  selector: 'app-page-contact-three',
  templateUrl: './page-contact-three.component.html',
  styleUrls: ['./page-contact-three.component.css']
})

/**
 * Page Contact-Three Component
 */
export class PageContactThreeComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  public siteKey = '';
  public recaptcha = "";
  public contactForm: UntypedFormGroup;
  constructor(
    private modalService: NgbModal,
    public http: HttpClient,
    private metaTagService: Meta,
    private titleService: Title,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private canonicalService: CanonicalService,
    private _http: HttpClient, 
    ) { }
    get f() {
      return this.contactForm.controls;
    }
  
  from_name:boolean = false
  from_phone:boolean = false
  from_email:boolean = false
  from_success:boolean = false
  from_click:boolean = false
  from = {
    name : "",
    phone : "",
    email : "",
    subject : "สอบถามข้อมูลทั่วไป",
    message : "",
  }

  
  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareติดต่อเรา,ติดต่อ ซิสเนค,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Sysnect Information Co., Ltd.' },
      {name: 'description', content: 'ติดต่อ บริษัท ซิสเนค อินฟอเมชัน จำกัดได้ที่เลขที่ 380/301 ซอยสันนิบาตเทศบาล แขวงจันทรเกษม เขตจตุจักร กรุงเทพมหานคร 10900 '},
      { charset: 'UTF-8' }
    ]);
    this.titleService.setTitle("ติดต่อเรา - Sysnect Information Co., Ltd.");
    
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }
  validateForm(){
    this.blockUI.start(); // Stop blocking
    this.from_name = false
    this.from_phone = false
    this.from_email = false
    this.from_success = false
    this.from_click = true
    if(!this.from.name){
      this.from_name = true
      this.from_click = false
      this.blockUI.stop(); // Stop blocking
      return
    }
    if(!this.from.phone){
      this.from_phone = true
      this.from_click = false
      this.blockUI.stop(); // Stop blocking
      return
    }
    if(!this.from.email){
      this.from_email = true
      this.from_click = false
      this.blockUI.stop(); // Stop blocking
      return
    }
    var mail = {
      "subject" : ""+this.from.subject,
      "name" : ""+this.from.name,
      "message" : "<p><b>เรียน ทีมงาน Sysnect Information Co., Ltd.</b></p><p>ระบบแจ้งการสอบถามข้อมูลผ่าน sysnect.co.th</p><p><b>รายละเอียด</b></p><p>เรื่อง:<b> "+this.from.subject+"</b></p><p>ชื่อ - นามสกุล:<b> "+this.from.name+"</b></p><p>เบอร์โทรศัพท์:<b> "+this.from.phone+"</b></p><p>อีเมล:<b> "+this.from.email+"</b></p><p>ข้อความ:<b> "+this.from.message+"</b></p>",
      "email" : ["info@sysnect.co.th"],
      "cc" : [ this.from.email ]
      }
    console.log(mail)
    setTimeout(() => {
      this.from_success = true
      this.from_click = false
      this.from = {
        name : "",
        phone : "",
        email : "",
        subject : "สอบถามข้อมูลทั่วไป",
        message : "",
      }
      this.blockUI.stop(); // Stop blocking
    }, 2000);
    this.http.post('https://qtmy460cog.execute-api.ap-southeast-1.amazonaws.com/default/v1_mail',mail)
      .subscribe((res:any) => {
        if(res.status=="success"){
          this.from_success = true
          this.from_click = false
          this.from = {
            name : "",
            phone : "",
            email : "",
            subject : "สอบถามข้อมูลทั่วไป",
            message : "",
          }
          this.blockUI.stop(); // Stop blocking
        }else{
          this.blockUI.stop(); // Stop blocking
        }
      })
  }
}
