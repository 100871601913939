
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">คำถามที่พบบ่อย </h4>

          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">SYSNECT</a></li>
                <li class="breadcrumb-item active" aria-current="page">คำถามที่พบบ่อย</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
        <div class="rounded shadow p-4 sticky-bar">
          <ul class="list-unstyled mb-0">
            
            <li class="mt-4">
              <a 
              href="javascript:void(0);" 
              [ngxScrollTo]="'#cookies-policy'"
              class="mouse-down h6 text-dark"
              >
              นโยบายคุกกี้
              </a>
            </li>
            <li class="mt-4">
              <a 
              href="javascript:void(0);" 
              [ngxScrollTo]="'#privacy-policy'"
              class="mouse-down h6 text-dark"
              >
              นโยบายความเป็นส่วนตัว
              </a>
            </li>
            <li class="mt-4">
              <a 
              href="javascript:void(0);" 
              [ngxScrollTo]="'#complaint-resolution-policy'"
              class="mouse-down h6 text-dark"
              >
              นโยบายการแก้ไขปัญหาข้อร้องเรียน
              </a>
            </li>
            <li class="mt-4">
              <a 
              href="javascript:void(0);" 
              [ngxScrollTo]="'#support'"
              class="mouse-down h6 text-dark"
              >
              การช่วยเหลือ
              </a>
            </li>
            <li class="mt-4">
              <a 
              href="javascript:void(0);" 
              [ngxScrollTo]="'#service'"
              class="mouse-down h6 text-dark"
              >
              มาตรฐานการให้บริการ
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title mt-5" id="cookies-policy">
          <h4>นโยบายคุกกี้</h4>
        </div>
        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">รายละเอียด</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    นโยบายคุกกี้ฉบับนี้ได้อธิบายความหมายและวิธีการใช้คุกกี้ของเว็บไซต์ sysnect.co.th โปรดศึกษานโยบายคุกกี้ฉบับนี้เพื่อให้คุณสามารถเข้าใจแนวปฏิบัติของเราเกี่ยวกับการเก็บรวบรวม ใช้ หรือการเปิดเผยคุกกี้ รวมถึงทางเลือกในการใช้คุกกี้ของเรา<br>
                  </span>  
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">คุกกี้คืออะไร?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    คุกกี้ คือ ไฟล์ข้อมูลขนาดเล็กที่ฝังอยู่ในคอมพิวเตอร์หรืออุปกรณ์ของคุณเมื่อคุณเยี่ยมชมเว็บไซต์ หลังจากนั้นคุกกี้จะถูกส่งกลับไปยังเว็บไซต์ต้นทางในแต่ละครั้งของการเข้าเยี่ยมชมในครั้งถัดไปหรือส่งไปยังเว็บไซต์อื่นที่จดจำคุกกี้นั้นได้ คุกกี้เหล่านี้ถูกใช้เพื่อทำให้เว็บไซต์สามารถทำงานได้หรือทำงานได้อย่างมีประสิทธิภาพ รวมถึงการให้ข้อมูลแก่เจ้าของเว็บไซต์
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้แบบชั่วคราว (Session Cookies) คือ คุกกี้ที่มีระยะเวลาชั่วคราวจะถูกใช้ในระหว่างที่คุณเยี่ยมชมเว็บไซต์และจะสิ้นสุดลงเมื่อคุณปิดบราวเซอร์
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้แบบถาวร (Persistent Cookies) คือ คุกกี้ที่มีระยะเวลาอยู่ตลอดจนกว่าหมดอายุหรือถูกลบ ใช้จดจำการตั้งค่าของคุณภายในเว็บไซต์ และจะยังคงอยู่ในคอมพิวเตอร์หรือบนมือถือของคุณแม้ว่าคุณจะปิดเว็บไซต์นั้นแล้ว คุกกี้ดังกล่าวนี้จะช่วยเรื่องความสอดคล้องของข้อมูลและช่วยเพิ่มประสิทธิภาพการใช้งานในขณะที่คุณเยี่ยมชมเว็บไซต์หรือบริการ
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้ของบุคคลที่หนึ่ง คือ คุกกี้ที่ถูกกำหนดโดยเว็บไซต์ที่คุณกำลังเยี่ยมชม
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้ของบุคคลที่สาม คือ คุกกี้ถูกกำหนดโดยเว็บไซต์ของบุคคลที่สามแยกต่างหากจากไซต์ที่คุณกำลังเยี่ยมชม เช่น ผู้ให้บริการข้อมูล โฆษณา หรือการวิเคราะห์บนเว็บไซต์
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เทคโนโลยีที่คล้ายคลึงกัน คือ เทคโนโลยีที่จัดเก็บข้อมูลในบราวเซอร์หรืออุปกรณ์ของคุณเพื่อแบ่งปันข้อมูลหรือจัดเก็บข้อมูล เช่น แฟลชคุกกี้ HTML 5 และวิธีการอื่น ๆ ของซอฟต์แวร์แอปพลิเคชันบนเว็บไซต์
                    <br>
                  </span>  
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">เราใช้คุกกี้อย่างไร?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เราจะจัดเก็บข้อมูลการเข้าเยี่ยมชมเว็บไซต์ของเราผ่านคุกกี้หรือเทคโนโลยีที่คล้ายคลึงกัน โดยเราจะใช้เพื่อวัตถุประสงค์ดังต่อไปนี้:
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เพื่อให้การเข้าบัญชีผู้ใช้งานบนเว็บไซต์ของเราเป็นไปอย่างต่อเนื่องและปลอดภัย
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เพื่อบันทึกข้อมูลการใช้งานและการตั้งค่าของคุณบนเว็บไซต์ของเรา
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เพื่อวิเคราะห์พฤติกรรมการใช้งานของคุณบนเว็บไซต์ของเรา
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เพื่อพัฒนาประสิทธิภาพในการใช้งานและการเข้าถึงบริการของเรา
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    เพื่อเก็บรวบรวมความสนใจ และเพิ่มความสามารถในการตอบสนองความต้องการในการใช้งานของคุณมากขึ้น
                  </span>  
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">ประเภทคุกกี้ที่เราใช้?</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    <b>ประเภทคุกกี้ที่เราใช้บนเว็บไซต์ มีดังต่อไปนี้</b>
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้ที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies)
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เหล่านี้มีความสำคัญต่อการให้บริการบนเว็บไซต์แก่คุณและเพื่อให้คุณสามารถใช้คุณลักษณะบางอย่างได้ คุกกี้เหล่านี้ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่คุณได้ เราใช้คุกกี้ดังกล่าวนี้เพื่อให้บริการแก่คุณ
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เพื่อการทำงานของเว็บไซต์ (Functional Cookies)
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เหล่านี้ช่วยให้เราจดจำสิ่งที่คุณเลือกเมื่อคุณใช้งานเว็บไซต์ เช่น การจดจำรายละเอียดการเข้าสู่ระบบหรือการตั้งค่าภาษาของคุณ วัตถุประสงค์ของคุกกี้เหล่านี้มีเพื่อให้คุณได้รับประสบการณ์ที่เป็นส่วนตัวมากขึ้นและเพื่อหลีกเลี่ยงไม่ให้คุณต้องใส่ข้อมูลของคุณใหม่ทุกครั้งเมื่อคุณใช้งานเว็บไซต์ คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวรเนื่องจากยังคงอยู่ในอุปกรณ์ของคุณเพื่อให้เราใช้ในการเยี่ยมชมเว็บไซต์ครั้งต่อไปของคุณ คุณสามารถลบคุกกี้เหล่านี้ผ่านบราวเซอร์ของคุณได้
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เพื่อการวิเคราะห์/เพื่อประสิทธิภาพ (Analytical/Performance Cookies)
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เหล่านี้ช่วยให้เราจดจำและนับจำนวนผู้ใช้งานบนไซต์ของเรา รวมถึงทำให้เราเข้าใจถึงวิธีการที่ผู้ใช้งานใช้งานบนเว็บไซต์ของเรา สิ่งเหล่านี้จะช่วยปรับปรุงวิธีการทำงานของเว็บไซต์เรา เช่น เพื่อให้มั่นใจว่าผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้ง่ายขึ้น
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เพื่อการโฆษณา (Advertising Cookies)
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เหล่านี้ใช้เพื่อแสดงโฆษณาที่เกี่ยวข้องกับคุณ คุกกี้เหล่านี้จะจำกัดจำนวนครั้งที่คุณเห็นโฆษณาและช่วยให้เราประเมินประสิทธิภาพด้านการตลาด นอกจากนี้เราอาจใช้ข้อมูลที่ได้รับจากคุกกี้เหล่านี้เพื่อเสนอโฆษณาที่คุณอาจสนใจโดยขึ้นอยู่กับพฤติกรรมการใช้งานออนไลน์ของคุณที่ผ่านมา คุกกี้ดังกล่าวนี้เป็นคุกกี้แบบถาวร เราอาจแบ่งปันข้อมูลนี้กับบุคคลที่สามรวมถึงพันธมิตรทางธุรกิจของเรา
                    <br>
                  </span>  
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">ทางเลือกเกี่ยวกับคุกกี้</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    ในกรณีที่คุณไม่ประสงค์ให้มีการใช้งานคุกกี้บนเว็บไซต์ของเรา คุณสามารถเปลี่ยนแปลงได้ด้วยการตั้งค่าบนบราวเซอร์ของคุณเพื่อเลือกการใช้งานที่เหมาะสมกับคุณ หากคุณปิดการใช้งานหรือลบคุกกี้บางอย่างในการตั้งค่าบราวเซอร์ของคุณ ทั้งนี้ การใช้งานบนเว็บไซต์หรือคุณลักษณะบางอย่างอาจทำงานได้ไม่ตรงตามวัตถุประสงค์
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    หากคุณต้องการศึกษาเพิ่มเติมเกี่ยวกับการใช้คุกกี้และการตั้งค่าคุกกี้ดังกล่าว คุณสามารถเข้าไปที่หน้าเว็บไซต์ทางการบราวเซอร์ของคุณได้ คุณสามารถดูรายละเอียดเกี่ยวกับวิธีการปฏิเสธหรือการลบคุกกี้ ตลอดจนข้อมูลเพิ่มเติมทั่วไปเกี่ยวกับคุกกี้ได้ที่ <a href="www.allaboutcookies.org" rel="nofollow" target="_blank">www.allaboutcookies.org</a> 
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    คุกกี้เหล่านี้มีความสำคัญต่อการให้บริการบนเว็บไซต์แก่คุณและเพื่อให้คุณสามารถใช้คุณลักษณะบางอย่างได้ คุกกี้เหล่านี้ช่วยในการยืนยันตัวบุคคลของผู้ใช้งานและช่วยป้องกันการปลอมแปลงบัญชีผู้ใช้งาน หากไม่มีคุกกี้เหล่านี้เราอาจไม่สามารถให้บริการแก่คุณได้ เราใช้คุกกี้ดังกล่าวนี้เพื่อให้บริการแก่คุณ
                    <br>
                  </span> 
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การแก้ไขเปลี่ยนแปลงนโยบายคุกกี้</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เราอาจแก้ไขเปลี่ยนแปลงนโยบายคุกกี้ฉบับนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 20 มกราคม 2023

                    <br>
                  </span> 
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">รายละเอียดการติดต่อ</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    <b>ผู้ควบคุมข้อมูลส่วนบุคคล / เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</b>
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    บริษัท ซิสเนค อินฟอเมชัน จำกัด
                    <br>
                  </span>  
                  <span style="margin-left: 20px;">
                    111 อาคารทรู ดิจิทัล พาร์ค เวสต์, ห้อง 917, ชั้น 9, ถนนสุขุมวิท, แขวงบางจาก เขตพระโขนง กรุงเทพ 10260

                    <br>
                  </span> 
                  <span style="margin-left: 20px;">
                    อีเมล info@sysnect.co.th
                    <br>
                  </span> 
                  <span style="margin-left: 20px;">
                    เว็บไซต์ https://www.sysnect.co.th
                    <br>
                  </span> 
                  <span style="margin-left: 20px;">
                    หมายเลขโทรศัพท์ (+66)91 964 9642
                    <br>
                  </span> 
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        
        <div class="section-title mt-5" id="terms-and-conditions-refunds">
          <h4>นโยบายความเป็นส่วนตัว</h4>
        </div>
        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel  cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">การเข้าเยี่ยมชมเว็บไซต์</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> 
                  <span style="margin-left: 20px;"> 
                  ท่านสามารถเข้าเยี่ยมชมเว็บไซต์ของ www.sysnect.co.th
 ได้ เพื่อการค้นหาสินค้าและบริการต่างๆ
                  ของบริษัทฯ โดยที่ท่านไม่ต้องแจ้งข้อมูลเกี่ยวกับตัวท่านให้แก่ทางเว็บไซต์ หากท่านต้องการรับข่าวสาร
                  จากบริษัท ท่านสามารถลงทะเบียนได้ตามที่บริษัทฯ กำหนด โดยที่ท่านสามารถยกเลิกการรับข่าวสาร
                  ได้โดยส่งอีเมล์มาที่ info@sysnect.co.th
                  </span>
                  <br>                  
                  <span style="margin-left: 20px;"> เว็บไซต์ของบริษัท ได้ดำเนินการปรับปรุงเว็บไซต์อย่างต่อเนื่อง บริษัทฯ อาจใช้อุปกรณ์ ซอฟต์แวร์
                  เพื่อการเก็บรวบรวมข้อมูลในด้านพฤติกรรมการสืบค้นข้อมูล ของผู้ที่เขัามาเยี่ยมชมเว็บไซต์ของบริษัทฯ
                  ซึ่งบริษัทฯ จะนำข้อมูลดังกล่าวไปใช้ในวัตถุประสงค์เพื่อการปรับปรุงเว็บไซต์ให้เข้าถึงกลุ่มเป้าหมาย
                  ได้อย่างมีประสิทธิภาพ ซึ่งข้อมูลที่มีการรวบรวมจะเป็นข้อมูล
                  </span>
                  <br>
                  <span style="margin-left: 20px;">
                    1. วัน และเวลาในการเข้าเยี่ยมชมเว็บไซต์<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    2. การดูหน้าเว็บเพจต่างๆ<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    3. เวลาที่ใช้ในการเยี่ยมชม<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    4. ประเภทของการสืบค้น<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    5. ผู้ให้บริการอินเทอร์เน็ต<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    6. การเข้าเยี่ยมชมเว็บไซต์ต่างๆ ทั้งก่อนและหลังการเข้าชมเว็บไซต์ของบริษัทฯ<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    7. การดูการคลิกในแต่ละครั้งบนหน้าเว็บไซต์ต่างๆ<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    8. การใช้การวิเคราะห์ข้อมูลโดยใช้ ระบบอัตโนมัติ<br>
                  </span>       
                  <br>
                  <span style="margin-left: 20px;"> www.sysnect.co.th
  อาจทำการตรวจสอบถึงพฤติกรรมการสืบค้นข้อมูล ของลูกค้าที่เป็นสมาชิกของเว็บไซต์ และใช้บริการต่างๆ โดยส่วนหนึ่งของกระบวนการรักษาความปลอดภัยของเว็บไซต์นั้น
                  บริษัทฯ อาจใช้ระบบ “คุกกี้” (COOKIE) ในการตรวจสอบความถูกต้องแท้จริงของผู้เข้าเยี่ยมชมเว็บไซต์
                  ระบบ “คุกกี้” นั้น คือข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์ของผู้เข้าเยี่ยมชมเว็บไซต์ ในขณะที่ผู้เข้าเยี่ยมชมเว็บไซต์นั้นกำลังเข้าเยี่ยมชมเว็บไซต์ของบริษัทฯ โดย “คุกกี้” นั้นจะได้รับการติดตั้งโดยเว็บไซต์ของบริษัทฯซึ่งจะเก็บข้อมูลของท่านดังต่อไปนี้
                  <br>
                  </span>          
                  <span style="margin-left: 20px;">
                    1. หมายเลขไอพี (IP Address)<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    2. ชนิดของ Browser ที่ใช้ในการเข้าถึง<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    3. Web Page ที่เข้าเยี่ยมชม<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    4. เวลาที่เยี่ยมชม (Access Time)<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    5. เว็บไซต์ที่อ้างถึงเว็บของบริษัท (Referring Website)<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    6. หมายเลขเครื่อง (MAC Address)<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    7. การเข้าถึงที่ตั้งของอุปกรณ์ <br>
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="privacy-policy">
                  <h6 class="title mb-0 text-dark">การติดต่อสื่อสารกับลูกค้า</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    ทางเว็บไซต์ www.sysnect.co.th
 จะติดต่อกับท่านผ่านทางอีเมล์และการโทรศัพท์เท่านั้น
                    โดยจะพิจารณาติดต่อ ผ่านทางอีเมล์ก่อน โดยในส่วนหัวเรื่องของอีเมล์จะทำการแจ้งวัตถุประสงค์
                    ในการติดต่อทุกครั้ง<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    หากท่านต้องการติดต่อกลับมาไม่ว่าจะเป็นเรื่องสินค้าหรืออื่น ๆ ท่านสามารถติดต่อได้ทางหน้า
                    “ติดต่อเรา” หรือการอีเมล์หรือโทรศัพท์ ตามข้อมูลที่แจ้งในทุกหน้าของเว็บไซต์ ซึ่งท่านจะได้รับการติดต่อกลับภายใน 24 ชั่วโมง
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การรวบรวมและการเก็บรักษาข้อมูลส่วนบุคคล</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">ข้อมูลที่เกี่ยวข้องกับตัวลูกค้าของเว็บไซต์ www.sysnect.co.th
 นั้น จะถูกนำไปใช้เพื่อวัตถุประสงค์ในการดำเนินงานของบริษัทฯ และถูกต้องตามกฎหมายเท่านั้น อันประกอบไปด้วย 
                    การเพิ่มประสิทธิภาพให้บริการ และนำไปใช้เพื่อการออกแบบสินค้าหรือบริการต่างๆ และข้อเสนอพิเศษต่างๆ ที่ดีขึ้นกว่าเดิม
                    ซึ่งเป็นไปตามความต้องการของลูกค้า ข้อมูลของลูกค้าจะไม่ถูกนำไปใช้ เก็บรวบรวม หรือรักษาไว้โดยไม่ตรงตามวัตถุประสงค์
                    อย่างไรก็ตามในกรณีที่บริษัทฯ ต้องการเก็บข้อมูลโดยที่ไม่มีวัตถุประสงค์ในการดำเนินการดังกล่าวข้างต้น บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านโดยการขอข้อมูลจากท่านโดยตรง หากท่านเลือกที่จะให้ข้อมูลส่วนบุคคล ดังต่อไปนี้ ผ่านกระบวนการสมัคร
                    <br>
                  </span>   
                  <span style="margin-left: 20px;"> 
                    1. ชื่อ-นามสกุล<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    2. ที่อยู่<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    3. Email<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    4. เบอร์โทรศัพท์หรือเบอร์โทรสาร<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    5. ข้อมูลการติดต่อ ที่อยู่ รหัสไปรษณีย์<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    6. ข้อมูลทางการเงิน รวมถึงรายละเอียดบัตรธนาคารและบัตรชำระเงิน<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    7. ข้อมูลธุรกรรม รวมถึงรายละเอียดเกี่ยวกับการชำระเงินที่ดำเนินการผ่านเว็บไซต์ของเรา<br>
                  </span>          
                  <span style="margin-left: 20px;">
                    8. ข้อมูลทางเทคนิค รวมถึงที่อยู่อินเทอร์เน็ตโปรโตคอล (IP) ข้อมูลเข้าสู่ระบบ ประเภทและเวอร์ชันของเบราเซอร์ การตั้งค่าโซนเวลา และตำแหน่งที่ตั้งพารามิเตอร์ พารามิเตอร์ การระบุประเภทและเวอร์ชันของปลั๊กอินเบราว์เซอร์ และระบบปฏิบัติการแพลตฟอร์ม และเทคโนโลยีอื่นๆ บนอุปกรณ์ต่างๆ ที่คุณใช้เพื่อเข้าสู่เว็บไซต์ของเรา
                    <br>
                  </span>          
                  <span style="margin-left: 20px;">
                    9. ข้อมูลการใช้งาน รวมถึงข้อมูลเกี่ยวกับการใช้เว็บไซต์ของเรา
                    <br>
                  </span>          
                  <span style="margin-left: 20px;">
                    10. ข้อมูลการตลาดและการสื่อสาร รวมถึงการตั้งค่าของคุณในการรับสื่อการตลาดจากเราและบุคคลที่สามและการตั้งค่าการสื่อสารของคุณ
                    <br>
                  </span>          
                  <span style="margin-left: 20px;">
                    11. นอกจากนี้เรายังเก็บรวบรวม ใช้ และแชร์ ข้อมูลรวม เช่น ข้อมูลทางสถิติ หรือข้อมูลประชากรเพื่อวัตถุประสงค์ต่างๆ ข้อมูลรวมอาจมาจากข้อมูลส่วนบุคคลของคุณ แต่ไม่ถือว่าเป็นข้อมูลส่วนบุคคลเนื่องจากข้อมูลนี้ไม่ได้เปิดเผยตัวตนของคุณโดยทางตรงหรือทางอ้อม ตัวอย่างเช่น เราอาจรวมข้อมูลการใช้งานของคุณเพื่อคำนวณเปอร์เซ็นต์ของผู้ใช้ที่เข้าถึงคุณสมบัติเฉพาะของเว็บไซต์ อย่างไรก็ตามหากเรารวมหรือเชื่อมต่อข้อมูลรวมกับข้อมูลส่วนบุคคลของคุณเพื่อให้สามารถระบุตัวตนของคุณได้โดยทางตรงหรือทางอ้อม เราถือว่าข้อมูลรวมเป็นข้อมูลส่วนบุคคลซึ่งจะใช้ตามนโยบายส่วนบุคคลนี้    
                    <br>
                  </span>   
                  <br>
                  <span style="margin-left: 20px;">
                    ให้กับเว็บไซต์บริษัทฯ ตลอดจนกิจกรรมทางธุรกิจหรือการดำเนินธุรกรรมใดๆ ของท่านแก่เว็บไซต์บริษัทฯ แล้ว บริษัทฯ จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับ และจะปฏิบัติตามที่กฎหมายกำหนด
                    ข้อมูลเกี่ยวกับลูกค้าของบริษัทฯ จะถูกเก็บรวบรวม และรักษาไว้ในระหว่างระยะเวลาที่บริษัทฯ และท่านยังคงมีการติอต่อทางธุรกิจต่อกัน นอกจากนี้ เมื่อท่านได้ส่ง e-mail มายังบริษัทฯ บริษัทฯ จะเก็บเนื้อหาใน e-mail
                    ที่อยู่ของ e-mail และการโต้ตอบ e-mail ดังกล่าวไว้ เพื่อตอบข้อสงสัยให้แก่ท่าน หรือเพื่อความจำเป็นในการติดตามผล และเพื่อตรวจสอบถึงการโต้ตอบระหว่างลูกค้าและเจ้าหน้าที่ของบริษัทฯ ในส่วนที่ท่านมีข้อสงสัยนั้นว่าได้รับการแก้ไขหรือไม่
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การเปิดเผยข้อมูลส่วนบุคคล</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เว็บไซต์ www.sysnect.co.th
  จะรักษามาตรฐานในการรักษาความปลอดภัยโดยเคร่งครัด<br>
                  </span>    
                  <span style="margin-left: 20px;">
                      1. ท่านได้ร้องขอและให้อำนาจแก่เว็บไซต์บริษัท ในการดำเนินการ หรือ<br>
                    </span>          
                    <span style="margin-left: 20px;">
                      2. ข้อมูลที่ส่งต่อนั้นเป็นไปเพื่อช่วยให้การเริ่มต้นธุรกรรมของท่านสำเร็จลุล่วงลง หรือ<br>
                    </span>          
                    <span style="margin-left: 20px;">
                      3. การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาต หรือ<br>
                    </span>              
                  <span style="margin-left: 20px;">
                    เว็บไซต์ www.sysnect.co.th
   อาจทำความตกลงไว้กับผู้ให้บริการภายนอก (Third Party) ในการที่จะพัฒนาและดูแลรักษาระบบพาณิชย์อิเล็กทรอนิกส์ (e-commerce) ของบริษัท และการจัดสรรทรัพยากรหรือบริการในนามของบริษัท ซึ่งบุคคลภายนอกที่เข้ามาดำเนินงานให้กับบริษัท 
                    หรือเข้ามาดำเนินการในนามของบริษัท นั้น จะต้องตกลงที่จะรักษาข้อมูลลูกค้าของเว็บไซต์ www.sysnect.co.th
   ไว้เป็นความลับด้วยเช่นกัน อีกทั้งยังต้องผูกพันต่อกฎหมายที่มีผลใช้บังคับด้วย เมื่อใดก็ตามที่เว็บไซต์ www.sysnect.co.th

                  </span>   
                  <span style="margin-left: 20px;">
                    ได้ว่าจ้างองค์กรอื่นใด เข้ามาให้การสนับสนุนการบริการของบริษัท องค์กรเหล่านั้นจะต้องปฎิบัติตาม มาตรฐานการรักษาข้อมูลเป็นความลับของบริษัทฯ ด้วยเช่นกัน
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การเปิดเผยข้อมูลส่วนบุคคล</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เว็บไซต์ www.sysnect.co.th
  จะรักษามาตรฐานในการรักษาความปลอดภัยโดยเคร่งครัด<br>
                  </span>    
                  <span style="margin-left: 20px;">
                    ทั้งนี้ เพื่อวัตถุประสงค์ในการปกป้องความเสียหาย หรือการเข้าถึงข้อมูลส่วนบุคคลของท่านโดยไม่มีอำนาจ<br>
                  </span>       
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การรักษาความถูกต้องของข้อมูล</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เว็บไซต์ www.sysnect.co.th
 มีกระบวนการในการสร้างความมั่นใจว่า ข้อมูลของท่าน เป็นข้อมูลที่ถูกต้อง เป็นปัจจุบัน และมีความสมบูรณ์ตามมาตรฐานทางธุรกิจ นอกจากนี้ บริษัทฯ ยังมีกระบวนการในการแก้ไขข้อมูลที่ผิดพลาด ตามการร้องขอของท่านได้อีกด้วย
                    โดยท่านสามารถแก้ไขข้อมูลที่่ผิดพลาดด้วยวิธีการดังนี้
                    <br>
                  </span>    
                  <span style="margin-left: 20px;">
                    1.  ติดต่อแจ้งข้อมูลเข้ามาที่ info@sysnect.co.th<br>
                  </span>     
                  <span style="margin-left: 20px;">
                    2. ติดต่อแจ้งข้อมูลเข้ามาที่ (+66)91 964 9642<br>
                  </span>       
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การตรวจสอบความถูกต้องของข้อมูล</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">
                    เว็บไซต์ www.sysnect.co.th
 มีกระบวนการในการตรวจสอบความถูกต้องของข้อมูล
                    ทั้งในส่วนทางด้านเทคนิค Programming และการตรวจสอบยืนยันจากข้อมูลอื่นๆ ของท่าน<br>
                  </span>    
                  <span style="margin-left: 20px;">
                    1. การใช้เทคโนโลยี RPA (Robotic Process Automation) มาช่วยในการวิเคราะห์ข้อมูล<br>
                  </span>     
                  <span style="margin-left: 20px;">
                    2. การตรวจสอบแหล่งที่มาของข้อมูลโดยใช้ AI<br>
                  </span>       
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

        </div>

        <div class="section-title mt-5" id="complaint-resolution-policy">
          <h4>นโยบายการแก้ไขปัญหาข้อร้องเรียน</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel  cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">มาตรการคุ้มครองและรักษาความลับของผู้ร้องเรียน</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  <span style="margin-left: 20px;">เพื่อเป็นการคุ้มครองสิทธิของผู้ร้องเรียนและผู้ให้ข้อมูลที่กระทำโดยเจตนาสุจริตทาง 
                  www.sysnect.co.th
 จะปกปิดชื่อ ที่อยู่ หรือข้อมูลใด ๆ ที่สามารถระบุตัวผู้ร้องเรียนหรือผู้ให้ข้อมูลได้ 
                  และเก็บรักษาข้อมูลของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ 
                  โดยจำกัดเฉพาะผู้รับผิดชอบในการดำเนินการตรวจสอบเรื่องร้องเรียนเท่านั้นที่จะเข้าถึงข้อมูลดังกล่าวได้ทั้งนี้ 
                  ผู้ได้รับข้อมูลจากการปฏิบัติหน้าที่ที่เกี่ยวข้องกับเรื่องร้องเรียน มีหน้าที่เก็บรักษาข้อมูล ข้อร้องเรียน 
                  และเอกสารหลักฐานของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ ห้ามเปิดเผยข้อมูลแก่บุคคลอื่นที่ไม่มีหน้าที่เกี่ยวข้อง 
                  เว้นแต่เป็นการเปิดเผยตามหน้าที่ที่กฎหมายกำหนด
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">การดำเนินการเมื่อได้รับเรื่องร้องเรียน</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">                  
                  <span style="margin-left: 20px;">
                    www.sysnect.co.th
 จะเป็นผู้พิจารณาเรื่องร้องเรียนที่ได้รับทันที และแจ้งให้หน่วยงานที่เกี่ยวข้องดำเนินการสอบสวนข้อเท็จจริงเกี่ยวกับเรื่องที่ได้รับ การร้องเรียนและดำเนินการแก้ไขอย่างเหมาะสม <br>
                  </span>
                  <span style="margin-left: 20px;">
                  www.sysnect.co.th
 จะติดตามผลความคืบหน้าเป็นระยะ ซึ่งหน่วยงานที่เกี่ยวข้องจะแจ้งผลการดำเนินงาน ภายในระยะเวลาที่เหมาะสมให้แก่ผู้ร้องเรียนทราบ โดยไม่มีค่าใช้จ่าย<br>
                </span></p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">ช่องทางการรับเรื่องร้องเรียน</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">                  
                  <span style="margin-left: 20px;">
                    ผู้ร้องเรียนจะต้องระบุรายละเอียดของเรื่องที่จะร้องเรียน พร้อมชื่อ ที่อยู่ และหมายเลขโทรศัพท์ที่สามารถติดต่อได้ และส่งมาได้ 3 ช่องทาง คือ<br>
                  </span>
                  <span style="margin-left: 20px;">
                    <b>1. จดหมายทางไปรษณีย์ </b> 
                    ฝ่ายรับเรื่องร้องเรียน เลขที่ 111 อาคารทรู ดิจิทัล พาร์ค เวสต์, ห้อง 917, ชั้น 9, ถนนสุขุมวิท, แขวงบางจาก เขตพระโขนง กรุงเทพ 10260

                  </span> <br>
                  <span style="margin-left: 20px;">
                    <b>2. จดหมายทาง Email</b> 
                    info@sysnect.co.th
                  </span> <br>
                  <span style="margin-left: 20px;">
                    <b>3. ติดต่อผ่าน WebSite</b> 
                    www.sysnect.co.th

                  </span> <br>
                  <span style="margin-left: 20px;">
                    <b>4. ฝ่ายบริการลูกค้า</b> 
                    (+66)91 964 9642
                  </span> <br>
                  <span style="margin-left: 20px;">
                    หากกระบวนการแก้ไขข้อพิพาทโดยผู้ประกอบการไม่เป็นที่พอใจของลูกค้า ลูกค้าสามารถใช้ช่องทางภายนอกที่ลูกค้าสามารถดำเนินการระงับข้อพิพาทได้  โดยติดต่อมายัง ฝ่ายบริการลูกค้า หรือ info@sysnect.co.th<br>
                  </span>
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark">ช่องทางการระงับข้อพิพาทโดยกลไกภายนอก</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">      
                  <span style="margin-left: 20px;">
                    หากกระบวนการแก้ไขข้อพิพาทโดยผู้ประกอบการไม่เป็นที่พอใจของลูกค้า ลูกค้าสามารถใช้ช่องทางภายนอกที่ลูกค้าสามารถดำเนินการระงับข้อพิพาทได้ โดยติดต่อมายัง <br>
                    <b>กรมพัฒนาธุรกิจการค้า กระทรวงพาณิชย์</b><br>
                    563 ถนนนนทบุรี ตำบลบางกระสอ อำเภอเมือง จังหวัดนนทบุรี 11000<br>
                    โทร. 0-2547-5960 โทรสาร. 0-2547-5973 (กองพาณิชย์อิเล็กทรอนิกส์)<br>
                    เว็บไซต์ : <a href="https://www.trustmarkthai.com">www.trustmarkthai.com</a><br>
                    อีเมล : <a href="mailto:e-commerce@dbd.go.th">e-commerce@dbd.go.th</a><br>
                  </span> 
                  <br>
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div class="section-title mt-5" id="support">
          <h4>การช่วยเหลือ</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel  cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark">ช่องทาง <span class="text-primary">สำนักงาน</span></h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">เลขที่ 111 อาคารทรู ดิจิทัล พาร์ค เวสต์, ห้อง 917, ชั้น 9, ถนนสุขุมวิท, แขวงบางจาก เขตพระโขนง กรุงเทพ 10260
</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark">ช่องทาง <span class="text-primary">อีเมล</span></h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans"> อีเมล  <a href="mailto:info@sysnect.co.th">info@sysnect.co.th</a></p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark">ช่องทาง <span class="text-primary">คอลเซ็นเตอร์</span></h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Phone : <a href="tel:+66919649642">(+66)91 964 9642</a>
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <div class="section-title mt-5" id="service">
            <h4>มาตรฐานการให้บริการ</h4>
          </div>
  
          <div class="faq-content mt-4 pt-3">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
              <ngb-panel  cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark">การบริการ</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">การบริการลูกค้าตลอด 24 ชม. ทุกวัน และมีทีมงานที่มีประสบการณ์และความเชี่ยวชาญเพื่อแก้ไขปัญหาของลูกค้า อย่างมีประสิทธิภาพและรวดเร็ว สามารถติดต่อสอบถาม/แจ้งปัญหา ได้ที่ <a routerLink="/contact">ติดต่อเรา</a> หรือ อีเมล  <a href="mailto:info@sysnect.co.th">info@sysnect.co.th</a></p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">ระบบความปลอดภัย</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">ระบบป้องกันสแปมและไวรัส ด้วยระบบ Cloud รวดเร็วปลอดภัย ด้วยการเข้ารหัส.</p>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

<!-- Back to top -->
<a href="javascript:void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->