import { Component,ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { CanonicalService } from './shared/canonical.service';
import { environment } from '../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient  } from '@angular/common/http';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild("modalNewsUpdate", { static: false }) content: any;
  addclass: string;
  buttonShow: boolean;
  TopbarShow: boolean;
  footerClass: string;
  developerPage: boolean;
  hideFooter: boolean;
  shopPages: boolean;
  constructor(
    private router: Router,
    private metaTagService: Meta,
    private titleService: Title,
    private canonicalService: CanonicalService,
    private deviceService: DeviceDetectorService,
    private http:HttpClient,
		config: NgbModalConfig,
		private modalService: NgbModal,
    ) {
      window.console.log = () => { }
      window.console.error = () => { }
      this.canonicalService.setCanonicalURL();
      this.metaTagService.addTags([
        { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Sysnect Information Co., Ltd.' },
        { charset: 'UTF-8' }
      ]);
      setTimeout(()=>{
        //this.modalService.open(this.content);
      },100)
      this.titleService.setTitle("บริษัท ซิสเนค อินฟอเมชัน จำกัด| Sysnect Information Co., Ltd.");
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        window['Unicons']['refresh']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  /**
   * Router activation
   */
  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
    this.buttonShow = componentReference.buttonList;
    this.TopbarShow = componentReference.sliderTopbar;
    this.footerClass = componentReference.footerVariant;
    this.developerPage = componentReference.isdeveloper;
    this.hideFooter = componentReference.hideFooter;
    this.shopPages = componentReference.shopPages;
  }
}
