  <!-- Hero Start -->
  <section class="bg-half d-table w-100" id="home" style="background: url('{{item.thumbnail}}') center;" id="home">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">SYSNECT</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/news" aria-current="page">ข่าวสาร</a></li>
                  <li class="breadcrumb-item active">{{item.title}}</li>

                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
</section><!--end section-->
<!-- Blog STart -->
<section class="section">
    <div class="container">
        <div class="row">
            <!-- BLog Start -->
            <div>
                <div class="me-lg-5">
                    <ul class="list-unstyled d-flex justify-content-between mt-4">
                        <li class="list-inline-item user me-2"><a href="javascript:void(0)" class="text-muted"><i class="uil uil-user text-dark"></i> {{item.author}} </a></li>
                        <li class="list-inline-item date text-muted"><i class="uil uil-calendar-alt text-dark"></i> {{item.dateCreated | date: 'dd/MM/yyyy'}}</li>
                    </ul>

                    <div class="view-content" [innerHTML]="item.content"></div>
                </div>
            </div><!--end col-->
            <!-- BLog End -->
        </div><!--end row-->
    </div><!--end container-->
</section><!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
