import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Response} from 'express';
import { Title,Meta } from '@angular/platform-browser';
import { CanonicalService } from '../../../shared/canonical.service';

declare let gtag: Function;
@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})

/**
 * PAge Error Component
 */
export class PageErrorComponent implements OnInit {

  hideFooter = true;
  constructor(
    private metaTagService: Meta,
    private titleService: Title,
    private canonicalService: CanonicalService,
    ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
      { name: 'robots', content: 'noindex, nofollow' },
      { name: 'author', content: 'Sysnect Information Co., Ltd.' },
      { charset: 'UTF-8' }
    ]);
  }
  

}
