import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  /**
   * Clients Logo
   */
   clients_logo = [];

  constructor(
    public http: HttpClient,
    ) { }

  ngOnInit(): void {
    this.http.get('https://api.sysnect.co.th/frontend/v1/customers')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.clients_logo = res.data
        }
      })
  }

}
