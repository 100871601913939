import { Component ,OnInit,ViewEncapsulation} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CanonicalService } from '../../../shared/canonical.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-corporate-blog-detail',
  templateUrl: './corporate-blog-detail.component.html',
  styleUrls: ['./corporate-blog-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CorporateBlogDetailComponent {
  item:any = {}
    constructor(
      private modalService: NgbModal,
      private metaTagService: Meta,
      private titleService: Title,
      public http: HttpClient,
      private canonicalService: CanonicalService,
      private _route:ActivatedRoute,
      private _router: Router,
    ) { }
    
    ngOnInit(){
      this.canonicalService.setCanonicalURL();
      this.metaTagService.addTags([
        { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเกี่ยวกับเรา,เกี่ยวกับ ซิสเนค,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Sysnect Information Co., Ltd.' },
        {name: 'description', content: 'Sysnect Information เป็น บริษัทฯ ที่รับพัฒนาระบบให้กับลูกค้าทั้งรายเล็กและรายใหญ่ รวมไปถึงการบริการด้านต่างๆ อาทิ การดูแลเว็บไซต์ เป็นต้น'},
        { charset: 'UTF-8' }
      ]);
      this.http.get('https://api.sysnect.co.th/frontend/v1/post/'+this._route.snapshot.params.id)
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.item = res.data
          this.titleService.setTitle(this.item.title+" - Sysnect Information Co., Ltd.");
      
          this.metaTagService.addTags([
            { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเกี่ยวกับเรา,เกี่ยวกับ ซิสเนค,เช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
            { name: 'robots', content: 'index, follow' },
            { name: 'author', content: 'Sysnect Information Co., Ltd.' },
            { name: 'description', content: (this.item.content).replace(/(<([^>]+)>)/gi, "")},
            { charset: 'UTF-8' }
          ]);
        }
    })
  }
}