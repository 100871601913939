import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  //Get Year
  year = new Date().getFullYear()

  constructor() { }

  ngOnInit(): void {
  }
  trustmarkthai(){
    window.open("https://www.trustmarkthai.com/callbackData/popup.php?data=03eb-19-6-c722b81b8e18360104141f3429c8ba7ba829e85b06&markID=firstmar", "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=700, height=700, left=0, top=0 " );
  } 
}
