import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CanonicalService } from '../../../shared/canonical.service';

declare let gtag: Function;
@Component({
  selector: 'app-index-services',
  templateUrl: './index-services.component.html',
  styleUrls: ['./index-services.component.css']
})

/***
 * Services Component
 */
export class IndexServicesComponent implements OnInit {

  /***
   * Footer bg color set
   */
  footerClass: true;
  footerVariant = 'bg-light';
  type = ""

  constructor(
    private modalService: NgbModal,
    private _route:ActivatedRoute,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private titleService: Title,) { 

    }
  ngOnInit(){
    this._route.params.subscribe(rs=>{
      console.log(rs["id"])
      this.type = ""+rs["id"]
      this.canonicalService.setCanonicalURL();
    })
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
