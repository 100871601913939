import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title,Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CanonicalService } from '../../../shared/canonical.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
declare let gtag: Function;
@Component({
  selector: 'app-index-business',
  templateUrl: './index-business.component.html',
  styleUrls: ['./index-business.component.css']
})

/***
 * Business Component
 */
export class IndexBusinessComponent implements OnInit {

  navClass = 'nav-light';
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      900: {
        items: 5
      }
    },
    nav: false
  };
  testimonialData = [];

  partnerData = [];
  /**
   * Services Data
   */
  servicesData = [];
  /**
   * Blog Data
   */
  
  /**
   * Blog Data
   */
   blogData = [];


  year = new Date().getFullYear()*1 - 2001
  constructor(
    private modalService: NgbModal,
    private metaTagService: Meta,
    private titleService: Title,
    public http: HttpClient,
    private canonicalService: CanonicalService,
  ) { }
  
  ngOnInit(){
    this.canonicalService.setCanonicalURL();
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Sysnect,ซิสเนค,ซิสเนค อินฟอเมชัน,เทคโนโลยีสารสนเทศ,การให้คำปรึกษา,Computer Server,Super Computer, UNIX,Network Equipment,Cisco, 3Com, Zyxel, D-Link, Linksys,Datalogic, PCS,Twincom,Video Conference,Samsung, Sony, Optoma, Epson, Kiosk, Toshiba, RFIDsec,Digital Nanoparticle Authentication,Network Security Equipment, Firewall,e-Mail Security,hardware,softwareเช่าพื้นที่,แพลตฟอร์ม,Sysnect Information' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Sysnect Information Co., Ltd.' },
      {name: 'description', content: 'Sysnect Information เป็น บริษัทฯ ที่รับพัฒนาระบบให้กับลูกค้าทั้งรายเล็กและรายใหญ่ รวมไปถึงการบริการด้านต่างๆ อาทิ การดูแลเว็บไซต์ เป็นต้น'},
      { charset: 'UTF-8' }
    ]);
    this.titleService.setTitle("บริษัท ซิสเนค อินฟอเมชัน จำกัด | Sysnect Information Co., Ltd.");

    this.http.get('https://api.sysnect.co.th/frontend/v1/customers')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.testimonialData = res.data
        }
    })
    this.http.get('https://api.sysnect.co.th/frontend/v1/services')
      .subscribe((res:any) => {
        if(res.status == "success"){
          var array = res.data
          array.forEach(element => {
            if(element.isHomePage){
              this.servicesData.push(element)
            }
          });
        }
    })
    this.http.get('https://api.sysnect.co.th/frontend/v1/partners')
      .subscribe((res:any) => {
        if(res.status == "success"){
          this.partnerData = res.data
        }``
    })
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }


}
