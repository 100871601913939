import { Component, OnInit ,Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {

  /**
   * Clients Logo
   */
  @Input() items:any
  constructor(
    public http: HttpClient,
    ) { }

  ngOnInit(): void {
  }

}
