<div *ngIf="!hideFooter">
        <footer class="footer-new">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12 mb-0 pb-0 pb-md-2">
                        <a  href="javascript:void(0);"  routerLink="/" class="logo-footer">
                            <img src="https://cdn.sysnect.co.th/images/logo.png" height="40" alt="">
                        </a>
                        <p class="mt-1 ">Sysnect Information Co., Ltd.<br>
                            เลขที่ 111 อาคารทรู ดิจิทัล พาร์ค เวสต์,<br> ห้อง 917, ชั้น 9, ถนนสุขุมวิท, <br>แขวงบางจาก เขตพระโขนง กรุงเทพ 10260<br>
                            Phone: <a href="tel:+66919649642" class="">(+66)91 964 9642</a><br>
                            Email: <a href="mailto:info@sysnect.co.th" class="">info@sysnect.co.th</a>
                            <br>
                        </p>
                    </div>
                    <!--end col-->

                    <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <p class="mt-5 ">
                            <a routerLink="/faqs" class="">นโยบายความเป็นส่วนตัว</a>
                            <br>
                            <a routerLink="/faqs" class="">นโยบายการแก้ไขปัญหาข้อร้องเรียน</a>
                            <br>
                            <a routerLink="/faqs" class="">คำถามที่พบบ่อย</a>
                            <br>
                            <a routerLink="/contact" class="">ติดต่อเรา</a>
                        </p>
                    </div>
                    <!--end col-->

                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
</div>